// zabezpieczenie stron które są dostępne tylko zalogowanych komorników

import { useContextStore } from '~coreStores/context.store';
import { useUserAuthStore } from '~coreStores/user-auth.store';

import { getRedirectUrl, removeRedirectUrl, saveRedirectUrl } from '~coreUtils/save-login-redirect';

export default defineNuxtRouteMiddleware(async (to, from) => {
  const { $routeNames } = useNuxtApp();

  const userAuthStore = useUserAuthStore();
  const { accessToken, refreshToken } = userAuthStore.getAuthTokens();

  const contextStore = useContextStore();
  const { getActiveContext } = storeToRefs(contextStore);

  /* 
    jeśli komornik/pracownik kancelarii nie jest zalogowany (nie ma refresh tokena) 
    lub 
    jeśli nie ma danych komornika/pracownika kancelarii w store
  */
  if (!refreshToken || !userAuthStore.getBailiffOfficeUserData) {
    // lista route'ow dla których nie zapamiętujemy adresu URL
    const routes = [$routeNames.bailiff.login, $routeNames.bailiff.context, $routeNames.index];
    if (typeof to.name === 'string' && !routes.includes(to.name)) {
      saveRedirectUrl(to.fullPath);
    }

    return navigateTo({ name: $routeNames.bailiff.login, query: from.query });
  }

  // jeśli komornik/pracownik kancelarii ma refresh token, ale nie ma access tokena to odświeżamy token
  if (refreshToken && !accessToken) {
    await userAuthStore.refreshTokenAction();
  }

  if (to.name !== $routeNames.bailiff.context) {
    const redirectUrl = getRedirectUrl();
    if (redirectUrl) {
      removeRedirectUrl();

      return navigateTo(redirectUrl);
    }
  }

  // zabezepieczenie które powoduje, że komornik/pracownik kancelarii bez wybranego kontekstu nie dostanie się do panelu pracownika
  if (to.name !== $routeNames.bailiff.context && !getActiveContext.value) {
    contextStore.handleContext(true, from.query);
  }
});
